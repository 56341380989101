import { IonButton, IonContent, IonPage } from '@ionic/react';
import { useParams } from 'react-router-dom';
import { useContext, useEffect, useState } from 'react';
import ContactTab from '../../components/ContactTab';
import InfoButton from '../../components/InfoButton';
import Header from '../../components/Profile/Header';
import Portfolio from '../../components/Profile/Portfolio';
import AboutTab from '../../components/Profile/ProfileTabs/AboutTab';
import ServicesTab from '../../components/ServicesTab';
import Tabs from '../../components/Tabs';
import { ProfileContext } from '../../context/profileContext';
import '../../style/page/profile.css';
import PortfolioTab from '../../components/PortfolioTab';
import hlyxLogo from '../../assets/HlyxLogo.svg';
import axios from 'axios';
import { baseUrl } from '../../config';
export const ProfileTabs = ['Contact', 'About', 'Services', 'Portfolio'];

const ProfileTabsContent = ({ activeTab }: { activeTab: string }) => {
  const { userProfile } = useContext(ProfileContext);

  const ProfileTabs = [
    <ContactTab />,
    <AboutTab />,
    <ServicesTab />,
    <Portfolio />,
  ];
  return userProfile?.name === 'Dr. Kenji Awurama' && activeTab === '3' ? (
    <PortfolioTab />
  ) : (
    ProfileTabs[+activeTab]
  );
};

const Profile = () => {
  const [activeTab, setActiveTab] = useState('0');
  const [cardType, setCardType] = useState('');

  const params = useParams();
  const { cardId } = params;
  useEffect(() => {
    getCardInfo();
  }, []);

  const getCardInfo = () => {
    axios
      .get(`${baseUrl}/card/find/base-info/${cardId}`)
      .then((res) => {
        console.log('resofCard', res?.data?.card);
        setCardType(res?.data?.card?.profileLayout);
      })
      .catch((err) => {
        if (err?.response?.status === 404) {
          window.location.pathname = '/page-not-found';
        }
        console.log(err);
      });
  };

  return (
    <>
      <IonPage>
        <IonContent className='overflow-auto profile-container'>
          <div className='sticky-header'>
            <Header />
            {cardType === 'minimal' ? (
              <ProfileTabsContent activeTab={activeTab} />
            ) : (
              cardType === 'premium' && (
                <>
                  <Tabs
                    tabList={ProfileTabs}
                    activeTab={activeTab}
                    setActiveTab={setActiveTab}
                  />
                  <ProfileTabsContent activeTab={activeTab} />
                </>
              )
            )}

            {/* {cardId ? (
            <HlyxContactTab />
          ) : ( */}
            <div
              style={{ paddingBlock: 10, backgroundColor: 'var(--bg-color)' }}
            >
              <div className='line-btn' />
              <IonButton
                size='large'
                className='outline-btn'
                fill='outline'
                expand='block'
                shape='round'
                style={{
                  fontWeight: 600,
                  fontSize: 15,
                  lineHeight: '17px',
                  fontFamily: 'Poppins',
                }}
              >
                Create Your Free Profile
              </IonButton>
              <div className='network_div'>
                <p>Your network empowered by</p>
                <div>
                  <img src={hlyxLogo} alt='hlyxLogo' />
                </div>
              </div>
            </div>
          </div>
          {/* )} */}
        </IonContent>
        <InfoButton />
      </IonPage>
    </>
  );
};

export default Profile;
