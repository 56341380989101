import { FC } from 'react';
import {
  LinksIcon,
  MessageIcon,
  ShareIcon,
} from '../../../../../assets/icons/AboutTabsIcons';
import '../../../../../style/components/BusinessNeeds.css';
import { AboutProps } from '../../../../../interface';
import moment from 'moment';

interface IBusinessNeeds {
  aboutData: AboutProps;
}

const BusinessNeeds: FC<IBusinessNeeds> = ({ aboutData }) => {
  return (
    <div className='business-list'>
      {aboutData?.businessNeeds?.map(
        ({ description, date, title, status }: any, index: number) => (
          <div key={index} className='bussiness-section'>
            <div className='blue-Dot'></div>
            <div className='Bussiness-card'>
              <div className='Business-card-main'>
                <p className='business_date'>
                  {moment(date).format(`MMMM D, YYYY`)}
                </p>
                <h6 className='business_needs_title'>{title}</h6>
                <p className='business_desc'>{description}</p>
              </div>
              <div className='status-row'>
                <p className={status === 'Active' ? 'green' : 'color_Blue'}>
                  {status}
                </p>
                <div className='card-icons'>
                  <LinksIcon />
                  <MessageIcon />
                  <ShareIcon />
                </div>
              </div>
            </div>
          </div>
        )
      )}
    </div>
  );
};

export default BusinessNeeds;
