export const LinksIcon = ({ color = "#FFFFFF" }: { color?: string }) => (
  <svg
    width="19"
    height="18"
    viewBox="0 0 19 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.0274 11.3895H7.89292C7.54287 11.3895 7.25258 11.0992 7.25258 10.7492C7.25258 10.3991 7.54287 10.1088 7.89292 10.1088H10.0274C12.2643 10.1088 14.0828 8.2903 14.0828 6.0534C14.0828 3.8165 12.2643 1.99795 10.0274 1.99795H5.75847C3.52157 1.99795 1.70303 3.8165 1.70303 6.0534C1.70303 6.99256 2.036 7.90609 2.63364 8.6318C2.85562 8.90501 2.82147 9.30628 2.54826 9.5368C2.27506 9.75879 1.87378 9.72466 1.64326 9.45145C0.849247 8.49521 0.413818 7.29138 0.413818 6.0534C0.413818 3.10786 2.8044 0.717285 5.74993 0.717285H10.0188C12.9644 0.717285 15.3549 3.10786 15.3549 6.0534C15.3549 8.99893 12.9729 11.3895 10.0274 11.3895Z"
      fill={color}
    />
    <path
      d="M13.4407 17.3673H9.17181C6.22627 17.3673 3.83569 14.9767 3.83569 12.0312C3.83569 9.08565 6.22627 6.69507 9.17181 6.69507H11.3063C11.6563 6.69507 11.9466 6.98535 11.9466 7.3354C11.9466 7.68545 11.6563 7.97574 11.3063 7.97574H9.17181C6.93491 7.97574 5.11636 9.79428 5.11636 12.0312C5.11636 14.2681 6.93491 16.0866 9.17181 16.0866H13.4407C15.6776 16.0866 17.4961 14.2681 17.4961 12.0312C17.4961 11.092 17.1632 10.1785 16.5655 9.45278C16.3435 9.17957 16.3777 8.7783 16.6509 8.54778C16.9241 8.31726 17.3254 8.35993 17.5559 8.63314C18.3499 9.58937 18.7854 10.7932 18.7854 12.0312C18.7768 14.9767 16.3862 17.3673 13.4407 17.3673Z"
      fill={color}
    />
  </svg>
);

export const MessageIcon = ({ color = "#FFFFFF" }: { color?: string }) => (
  <svg
    width="20"
    height="19"
    viewBox="0 0 20 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M18.7698 5.07318V10.2856C18.7698 10.7466 18.7185 11.1821 18.6033 11.5791C18.1294 13.4617 16.567 14.64 14.4154 14.64H10.9319L7.06412 17.2142C6.4878 17.6112 5.71938 17.1886 5.71938 16.497V14.64C4.41306 14.64 3.32447 14.2046 2.56886 13.449C1.80043 12.6805 1.36499 11.5919 1.36499 10.2856V5.07318C1.36499 2.63984 2.87622 0.962109 5.2071 0.744389C5.37359 0.731582 5.54008 0.71875 5.71938 0.71875H14.4154C17.028 0.71875 18.7698 2.46054 18.7698 5.07318Z"
      stroke={color}
      strokeWidth="1.38741"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const ShareIcon = ({ color = "#FFFFFF" }: { color?: string }) => (
  <svg
    width="17"
    height="19"
    viewBox="0 0 17 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.5066 17.3674C14.8858 17.3674 16.0039 16.2494 16.0039 14.8701C16.0039 13.4908 14.8858 12.3728 13.5066 12.3728C12.1273 12.3728 11.0093 13.4908 11.0093 14.8701C11.0093 16.2494 12.1273 17.3674 13.5066 17.3674Z"
      stroke={color}
      strokeWidth="1.38741"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13.5066 5.71531C14.8858 5.71531 16.0039 4.59722 16.0039 3.218C16.0039 1.83879 14.8858 0.720703 13.5066 0.720703C12.1273 0.720703 11.0093 1.83879 11.0093 3.218C11.0093 4.59722 12.1273 5.71531 13.5066 5.71531Z"
      stroke={color}
      strokeWidth="1.38741"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M3.51708 11.5414C4.8963 11.5414 6.01438 10.4233 6.01438 9.04405C6.01438 7.66479 4.8963 6.54675 3.51708 6.54675C2.13786 6.54675 1.01978 7.66479 1.01978 9.04405C1.01978 10.4233 2.13786 11.5414 3.51708 11.5414Z"
      stroke={color}
      strokeWidth="1.38741"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11.4249 4.46558L5.5979 7.79531"
      stroke={color}
      strokeWidth="1.38741"
    />
    <path
      d="M5.5979 10.2916L11.4249 13.6214"
      stroke={color}
      strokeWidth="1.38741"
    />
  </svg>
);

export const ClockIcon = () => (
  <svg
    width="17"
    height="16"
    viewBox="0 0 17 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16.0122 8.13464C16.0122 9.57254 15.5858 10.9781 14.787 12.1737C13.9882 13.3693 12.8528 14.3012 11.5244 14.8515C10.1959 15.4018 8.73418 15.5458 7.3239 15.2654C5.91361 14.985 4.61815 14.2927 3.60132 13.276C2.58449 12.2594 1.89196 10.964 1.61129 9.55378C1.33062 8.14355 1.47442 6.68176 2.02451 5.35325C2.5746 4.02473 3.50627 2.88917 4.70171 2.09014C5.89716 1.29111 7.30269 0.864503 8.74058 0.864258C9.69545 0.864095 10.641 1.05203 11.5232 1.41733C12.4054 1.78263 13.2071 2.31813 13.8823 2.99327C14.5576 3.6684 15.0932 4.46994 15.4587 5.3521C15.8241 6.23426 16.0122 7.17978 16.0122 8.13464V8.13464Z"
      stroke="#868FA0"
      strokeWidth="1.16326"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.74097 3.77136V8.13409L11.6499 9.58792"
      stroke="#868FA0"
      strokeWidth="1.16326"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
