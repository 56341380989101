import React, { useCallback, useState } from 'react';
import { useParams } from 'react-router-dom';
import Plus from '../../assets/icons/Plus.svg';
import Camera from '../../assets/icons/CameraIcon.svg';
import arrow from '../../assets/icons/Arrow.svg';
import editIcon from '../../assets/icons/EditIcon.svg';
import Mandatory from '../../assets/icons/Mandatory.svg';
import PencilIcon from '../../assets/icons/PencilIcon.svg';
import whiteCamera from '../../assets/whiteCamera.svg';
import { ReactComponent as GreySwipeBar } from '../../assets/icons/GreySwipeBar.svg';
import './LinkWith.css';
import Cropper from 'react-easy-crop';
import axios from 'axios';
import { baseUrl } from '../../config';
import { toast } from 'react-toastify';

interface LinkWithProps {
  setAddLink: (e: boolean) => void;
  name: string;
}

const LinkWith = ({ setAddLink, name }: LinkWithProps) => {
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [croppedImage, setCroppedImage] = useState('');
  const [zoom, setZoom] = useState(1);
  const [data, setData] = useState({
    fullName: '',
    email: '',
    phone: '',
    message: '',
    company: '',
    job: '',
    city: '',
    state: '',
    notes: '',
  });
  const [moreDetails, setMoreDetails] = useState(true);
  const [source, setSource] = useState('');
  const [edit, setEdit] = useState(false);
  const [image, setImage] = useState('');
  const params = useParams();
  const { cardId } = params;

  const body = new FormData();

  body.append('contactImage', image);
  if (cardId) {
    body.append('cardId', cardId);
  }

  Object.entries(data).forEach(
    ([key, value]) => value && body.append(key, value)
  );

  const handleSendContact = (e: React.SyntheticEvent) => {
    e.preventDefault();
    const token = localStorage.getItem('token');
    axios
      .post(`${baseUrl}/contact/create`, body, {
        headers: {
          Authorization: token,
        },
      })
      .then((res) => {
        setData({
          fullName: '',
          email: '',
          phone: '',
          message: '',
          company: '',
          job: '',
          city: '',
          state: '',
          notes: '',
        });
        setImage('');
        setSource('');
        setCroppedImage('');
        console.log(res);
      })
      .catch((err) => {
        toast.error(err?.response?.data?.message);
        console.log(err, 'err');
      });
  };

  const handleFileChange = (event: any) => {
    const file = event.target.files[0];
    setImage(file);
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        const source = reader.result as string;
        setSource(source || '');
      };
      reader.readAsDataURL(file);
    }
  };
  const handleOnChange = (e: any) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };
  const handleMoreDetails = () => {
    setMoreDetails(false);
  };
  const handleEditButton = () => {
    setEdit(!edit);
  };
  const handleSelfiePhoto = () => {
    const fileInput = document.getElementById('icon-button-file');
    if (fileInput) {
      fileInput.click();
    }
    setEdit(false);
  };
  const handleRemove = () => {
    setCroppedImage('');
    setEdit(false);
  };

  const handleCropChange = useCallback((newCrop: any) => {
    setCrop(newCrop);
  }, []);

  const handleCropComplete = useCallback(
    (croppedArea: any, croppedAreaPixels: any) => {
      if (source) {
        const image = new Image();
        image.src = source;

        image.onload = () => {
          const canvas = document.createElement('canvas');
          const ctx = canvas.getContext('2d');

          if (!ctx) {
            // Handle the case where canvas context cannot be obtained
            console.error('Failed to obtain canvas context');
            return;
          }
          canvas.width = croppedAreaPixels.width;
          canvas.height = croppedAreaPixels.height;

          ctx.drawImage(
            image,
            croppedAreaPixels.x,
            croppedAreaPixels.y,
            croppedAreaPixels.width,
            croppedAreaPixels.height,
            0,
            0,
            croppedAreaPixels.width,
            croppedAreaPixels.height
          );

          const croppedImageDataURL = canvas.toDataURL('image/jpeg');
          setCroppedImage(croppedImageDataURL);
        };
      }
    },
    [source]
  );
  const handleSave = () => {
    setSource('');
  };
  const handleWheel = (e: any) => {
    e.target.blur();
  };
  return (
    <div id='main' className='main_div'>
      <form onSubmit={handleSendContact} className='submain_div'>
        <button
          onClick={() => setAddLink(false)}
          style={{ all: 'unset', marginInline: 'auto', display: 'block' }}
        >
          <GreySwipeBar />
        </button>
        <div className='linkWith'>
          <span className='linkWith_span'>link with</span>
          <h2 className='linkWith_name'>{name}</h2>
        </div>
        <div className='editCam_div'>
          <div className='blackBG'>
            <img
              className={croppedImage && 'Dp'}
              src={croppedImage ? croppedImage : whiteCamera}
              alt='edit'
            />
            <img
              onClick={handleEditButton}
              className='pencilIcon'
              src={PencilIcon}
              alt='edit'
            />
            {edit && croppedImage && (
              <div className='options_div'>
                <p className='options_p'>Photo options</p>
                <div className='border_grey'></div>
                <div className='options_retake'>
                  <button onClick={handleSelfiePhoto} className='options_p'>
                    Retake
                  </button>
                  <div className='border_grey'></div>
                  <button onClick={handleRemove} className='options_p'>
                    Remove
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
        <div style={{ position: 'relative' }}>
          {source && (
            <Cropper
              image={source}
              crop={crop}
              aspect={1}
              onCropChange={handleCropChange}
              onCropComplete={handleCropComplete}
              zoom={zoom}
              onZoomChange={setZoom}
            />
          )}
          <div className='inputField_div'>
            <p className='label_field'>
              Full Name{' '}
              <img className='staric' src={Mandatory} alt='mandatory' />
            </p>
            <input
              onChange={handleOnChange}
              name='fullName'
              value={data.fullName}
              className='inputField'
              type='text'
              required
              placeholder='i.e.: First M. Last'
            />
            <div className='border'></div>
          </div>
          <div className='inputField_div'>
            <p className='label_field'>
              Email Address{' '}
              <img className='staric' src={Mandatory} alt='mandatory' />
            </p>
            <input
              onChange={handleOnChange}
              name='email'
              value={data.email}
              className='inputField'
              type='email'
              required
              placeholder='i.e.: name@domain.com'
            />
            <div className='border'></div>
          </div>
          <div className='inputField_div'>
            <p className='label_field'>
              Phone Number{' '}
              <img className='staric' src={Mandatory} alt='mandatory' />
            </p>
            <input
              onChange={handleOnChange}
              onWheel={handleWheel}
              name='phone'
              value={data.phone}
              className='inputField'
              type='number'
              required
              placeholder='i.e.: (408) 123-4567'
            />
            <div className='border'></div>
          </div>
        </div>
        {moreDetails && (
          <div className='inputField_div'>
            <p className='label_field'>Message</p>
            <textarea
              onChange={handleOnChange}
              name='message'
              value={data.message}
              className='inputField'
              placeholder='Enter a brief and simple message. '
            />
            <div className='border'></div>
          </div>
        )}
        {!moreDetails && (
          <div>
            <div className='inputField_div'>
              <p className='label_field'>Business Name</p>
              <input
                onChange={handleOnChange}
                name='company'
                value={data.company}
                className='inputField'
                type='text'
                placeholder='i.e.: ABC Business Co.'
              />
              <div className='border'></div>
            </div>
            <div className='inputField_div'>
              <p className='label_field'>Job Title</p>
              <input
                onChange={handleOnChange}
                name='job'
                value={data.job}
                className='inputField'
                type='text'
                placeholder='i.e.: Founder and CFO'
              />
              <div className='border'></div>
            </div>
            <div className='location_state_div inputField_div'>
              <div style={{ flexBasis: '54%' }}>
                <p className='label_field'>Location</p>
                <input
                  onChange={handleOnChange}
                  name='city'
                  value={data.city}
                  className='inputField'
                  type='text'
                  placeholder='i.e.: San Diego'
                />
                <div className='border mb-5'></div>
                <span className='city'>City</span>
              </div>
              <div style={{ flexBasis: '35%' }}>
                <input
                  onChange={handleOnChange}
                  name='state'
                  value={data.state}
                  className='inputField'
                  type='text'
                  placeholder='i.e.: CA'
                />
                <div className='border mb-5'></div>
                <span className='city'>State</span>
              </div>
            </div>
            <div className='inputField_div'>
              <p className='label_field'>Message</p>
              <div className='relative'>
                <textarea
                  onChange={handleOnChange}
                  name='message'
                  value={data.message}
                  className='inputField'
                  placeholder='Enter a brief and simple message. '
                />
                {data.message === '' && (
                  <img className='editIcon' src={editIcon} alt='edit' />
                )}
              </div>
              <div className='border'></div>
            </div>
            <div className='inputField_div'>
              <p className='label_field'>Notes</p>
              <input
                onChange={handleOnChange}
                name='notes'
                value={data.notes}
                className='inputField'
                placeholder='i. e.: We at the ACME Business Co. Galla. #Fundraiser2023,  #HollywoodBlvd. '
              />
              <div className='border'></div>
            </div>
          </div>
        )}
        <div className='button_div'>
          <button
            onClick={handleMoreDetails}
            type='button'
            className='plus_btn_div'
          >
            <img className='plus_img' src={Plus} alt='plus' />
            <p className='moreDetails_btn'>More Details</p>
          </button>
          <div>
            <input
              type='file'
              accept='image/*'
              capture='user'
              onChange={handleFileChange}
              id='icon-button-file'
              style={{ display: 'none' }}
            />

            <button
              onClick={source ? handleSave : handleSelfiePhoto}
              type='button'
              className='plus_btn_div blue_bg'
            >
              <img className='plus_img' src={Camera} alt='camera' />
              <p className='moreDetails_btn blue_bg'>
                {source ? 'Save Photo' : 'Selfie Photo'}
              </p>
            </button>
          </div>
        </div>
        <div className='button_div'>
          <button type='submit' className='plus_btn_div sendContact_btn'>
            <p className='moreDetails_btn sendContact'>Send Contact</p>
            <img className='plus_img' src={arrow} alt='camera' />
          </button>
        </div>
      </form>
    </div>
  );
};

export default LinkWith;
