import {
  IonButton,
  IonCard,
  IonCardContent,
  IonCardSubtitle,
} from '@ionic/react';
import '../../style/components/MediaCard/mediaCard.css';
import { BreifIcon, LinkBtnIcon, LocateIcon } from '../../assets/icons';
import { useContext, useEffect, useState } from 'react';
import { ProfileContext } from '../../context/profileContext';
import axios from 'axios';
import { baseUrl } from '../../config';
import { IContactData } from '../../interface'

const HeroSession = ({ contactData }: any) => {
  // const { userProfile } = useContext(ProfileContext);
  // const { image, jobTitle, name, address, companyLogo } = userProfile;
  // const[jobTitle]=userProfile.contact[]

  return (
    contactData && (
      <>
        <div style={{ position: 'relative' }}>
          <div className="cover_photo_container">
            <img
              alt='profile avatar'
              src={contactData?.coverPhoto}
              className="cover_photo"
            />
          </div>
          {/* {contactData ? (
            <img
              alt='company logo'
              className="joi-avatar-icon"
              src={contactData?.companyIcon}
            />
          ) : ( */}
          <img
            alt='company icon'
            className="avatar-icon"
            src={contactData?.companyIcon}
          />
          {/* )} */}
        </div>
        <IonCard style={{ paddingBottom: "40px" }} className="cover_photo_card ion_Card">
          <IonCardSubtitle>
            {contactData?.fullName}
          </IonCardSubtitle>

          <IonCardContent style={{ padding: "10px" }} className="ion-content">
            <div className="ion_text">
              <BreifIcon color="var(--text-color)" />
              <p>
                {contactData?.profTitle} at <b>{contactData?.company}</b>
              </p>
            </div>

            <div className="ion_text">
              <LocateIcon color="var(--text-color)" />
              <p>{`${contactData?.city ? contactData?.city : ''}, ${contactData?.state ? contactData?.state : ''}`}</p>
            </div>
          </IonCardContent>
        </IonCard>

        <IonCard className="link_card">
          <IonButton color="primary" expand="block" className="ion-btn">
            <div style={{ marginRight: 5 }}>
              <LinkBtnIcon />
            </div>
            Link with Me
          </IonButton>
        </IonCard>
      </>
    )
  );
};
export default HeroSession;
