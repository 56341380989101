import { IonCard, IonCardContent } from '@ionic/react';
import React, { useState, useContext, useEffect } from 'react';
import { BreifIcon, LocateIcon } from '../../../assets/icons/index';
import '../../../style/components/userDetailCard.css';
import { ProfileContext } from '../../../context/profileContext';
import axios from 'axios';
import { baseUrl } from '../../../config';

const UserDetailCard = ({ aboutData }: any) => {
  // const { userProfile } = useContext(ProfileContext);
  // const { image, name, jobTitle, address } = userProfile;
  // const [user, setUser] = useState<any>(null);

  // useEffect(() => {
  //   const token = localStorage.getItem('token');
  //   axios
  //     .get(`${baseUrl}/user/find/me`, {
  //       headers: {
  //         Authorization: token,
  //       },
  //     })
  //     .then((res) => {
  //       setUser(res?.data?.user);
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // }, []);
  return (
    <>
      {aboutData && (
        <IonCard className="user-detail-header">
          <IonCardContent className="user-detail">
            <img
              alt="profile_avatar"
              src={aboutData?.userAvatar}
              className="user-image"
            />
          </IonCardContent>
          <IonCardContent className="user-detail">
            <h1>{aboutData?.fullName}</h1>
            <div className="user-info">
              <BreifIcon height={10} width={12} color="var(--text-color)" />
              <p>
                {aboutData?.profTitle} at <b>{aboutData?.company}</b>
              </p>
            </div>
            <div className="user-info">
              <LocateIcon height={10} width={12} color="var(--text-color)" />
              <p>{aboutData?.city + ', ' + aboutData?.state}</p>
            </div>
          </IonCardContent>
        </IonCard>
      )}
    </>
  );
};

export default UserDetailCard;
