import { IonButton } from "@ionic/react";
import { useContext } from "react";
import { Link } from "react-router-dom";
import { BackBtnIcon, HlyxHeaderLogo } from "../../../assets/icons";
import { ProfileContext } from "../../../context/profileContext";
import "./avatarimage.css";

const Header = () => {
  const { userProfile } = useContext(ProfileContext);
  const { Avatar } = userProfile;
  return (
    <div className="sticky-header">
      <div className="profile-header-container">
        <Link to="/" replace={true}>
          <IonButton expand="full" className="back-btn">
            <BackBtnIcon color={"var(--text-color)"} />
          </IonButton>
        </Link>
        <HlyxHeaderLogo color={"var(--text-color)"} />
        <img
          className="avatarimage"
          src={Avatar}
          alt="avatar-header-icon"
          style={{
            top: 21,
            right: 24.89,
            width: 61,
            height: 61,
            borderRadius: 25,
            objectFit: "cover",
            padding: 2,
          }}
        />
      </div>
    </div>
  );
};

export default Header;
