import React, { FC, useContext } from "react";
import { BlueDotIcon } from "../../../../../assets/icons";
import { ProfileContext } from "../../../../../context/profileContext";
import "../../../../../style/components/achievement.css";
import ReadMoreText from "../../../../ReadMoreText";
import { AboutProps } from "../../../../../interface";
import moment from "moment";

interface IAchievements {
  aboutData: AboutProps
}

const Achievements: FC<IAchievements> = ({ aboutData }) => {
  // const { userProfile } = useContext(ProfileContext);
  // const { achievement } = userProfile?.about;
  return (
    <>
      {aboutData.achievements?.map(
        (
          { description, descriptiontitle, title, detail, startDate, endDate }: any,
          index: number
        ) => (
          <div className="achievement-card" key={index}>
            <BlueDotIcon color="#77859F" />
            <div className="achievement-description">
              <p className="year">{`${moment(startDate).format('YYYY')} - ${endDate ? moment(endDate).format('YYYY') : "Present"}`}</p>
              <div className="detail-block">
                <h3 className="achievement_title">{title}</h3>
                <p className="details">
                  <ReadMoreText text={description} limit={200} />
                </p>
              </div>
            </div>
          </div>
        )
      )}
    </>
  );
};

export default Achievements;
