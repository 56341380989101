export const BiDashboardIcon = () => (
  <svg
    width="36"
    height="28"
    viewBox="0 0 36 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.9978 10.2532C15.9978 12.9707 13.7944 15.1741 11.0769 15.1741C8.35936 15.1741 6.15601 12.9707 6.15601 10.2532C6.15601 7.53563 8.35936 5.33228 11.0769 5.33228C13.7944 5.33228 15.9978 7.53563 15.9978 10.2532Z"
      stroke="#8D919A"
      stroke-width="1.23537"
    />
    <path
      d="M33.2314 27.6929H2.76929C1.24202 27.6929 0 26.4508 0 24.9236V2.76929C0 1.24202 1.24202 0 2.76929 0H33.2314C34.7587 0 36.0007 1.24202 36.0007 2.76929V24.9236C36.0007 26.4508 34.7587 27.6929 33.2314 27.6929ZM2.76929 1.38464C2.00566 1.38464 1.38464 2.00566 1.38464 2.76929V24.9236C1.38464 25.6872 2.00566 26.3082 2.76929 26.3082H33.2314C33.9951 26.3082 34.6161 25.6872 34.6161 24.9236V2.76929C34.6161 2.00566 33.9951 1.38464 33.2314 1.38464H2.76929Z"
      fill="#8D919A"
    />
    <path
      d="M21.9949 13.5168L21.9949 10.6343"
      stroke="#8D919A"
      stroke-width="1.23537"
      stroke-linecap="round"
    />
    <path
      d="M7.78798 17.6345L6.55261 17.6345"
      stroke="#8D919A"
      stroke-width="1.23537"
      stroke-linecap="round"
    />
    <path
      d="M11.2882 17.6345L10.0529 17.6345"
      stroke="#8D919A"
      stroke-width="1.23537"
      stroke-linecap="round"
    />
    <path
      d="M14.9942 17.6345L13.7588 17.6345"
      stroke="#8D919A"
      stroke-width="1.23537"
      stroke-linecap="round"
    />
    <path
      d="M31.0541 17.6345L20.3475 17.6345"
      stroke="#8D919A"
      stroke-width="1.23537"
      stroke-linecap="round"
    />
    <path
      d="M24.6714 13.5167L24.6714 9.60474"
      stroke="#8D919A"
      stroke-width="1.23537"
      stroke-linecap="round"
    />
    <path
      d="M27.3479 13.5165L27.3479 7.75146"
      stroke="#8D919A"
      stroke-width="1.23537"
      stroke-linecap="round"
    />
    <path
      d="M30.0247 13.5167L30.0247 5.48682"
      stroke="#8D919A"
      stroke-width="1.23537"
      stroke-linecap="round"
    />
    <path
      d="M6.44946 23.6053L10.8113 21.3253C10.9178 21.2696 11.0434 21.2632 11.155 21.3079L17.4861 23.8403C17.6028 23.887 17.7343 23.8779 17.8434 23.8155L21.1347 21.9348C21.2242 21.8836 21.3297 21.8679 21.4302 21.8908L30.3333 23.9142"
      stroke="#8D919A"
      stroke-width="1.23537"
      stroke-linecap="round"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M17.9186 10.2613C17.9186 10.4291 17.7796 10.5611 17.6118 10.5611H11.494C11.1529 10.5611 10.8763 10.2846 10.8763 9.94343V4.02311C10.8763 3.67227 11.1691 3.38948 11.5174 3.43197C15.1293 3.87264 17.9186 6.76226 17.9186 10.2613Z"
      fill="#8D919A"
    />
  </svg>
);

export const UiUxIcon = () => (
  <svg
    width="34"
    height="36"
    viewBox="0 0 34 36"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.9235 14.5396V17.3089C15.9235 17.691 16.2336 18.0012 16.6158 18.0012H17.6764C17.882 18.0012 20.0995 15.4825 20.0995 15.4825C20.3959 15.1267 20.9504 15.1564 21.2073 15.5414C21.2073 15.5414 23.539 18.0005 23.2171 18.0005H24.9236C25.3058 18.0005 25.616 17.6903 25.616 17.3082V14.5389C25.616 14.1567 25.3058 13.8466 24.9236 13.8466H16.6158C16.2336 13.8472 15.9235 14.1574 15.9235 14.5396Z"
      fill="#8D919A"
    />
    <path
      d="M31.1545 0H2.07696C0.931865 0 0 0.931865 0 2.07696V24.9236V25.6159V28.3852C0 29.5303 0.931865 30.4621 2.07696 30.4621H13.8464V29.0775H2.07696C1.6948 29.0775 1.38464 28.7673 1.38464 28.3852V27.5655C1.60203 27.643 1.83257 27.6929 2.07696 27.6929H13.8464V26.3082H2.07696C1.6948 26.3082 1.38464 25.9981 1.38464 25.6159V24.9236V6.92321H31.8468V22.8466V25.6159C31.8468 25.9981 31.5366 26.3082 31.1545 26.3082H27.6929V27.6929H31.1545C31.3989 27.6929 31.6294 27.643 31.8468 27.5655V28.3852C31.8468 28.7673 31.5366 29.0775 31.1545 29.0775H27.6929V30.4621H31.1545C32.2996 30.4621 33.2314 29.5303 33.2314 28.3852V25.6159V22.8466V2.07696C33.2314 0.931865 32.2996 0 31.1545 0ZM14.1109 1.38464L16.1879 5.53857H12.8896L10.8127 1.38464H14.1109ZM1.38464 2.07696C1.38464 1.6948 1.6948 1.38464 2.07696 1.38464H9.26464L11.3416 5.53857H1.38464V2.07696ZM17.7359 5.53857L15.6589 1.38464H31.1545C31.5366 1.38464 31.8468 1.6948 31.8468 2.07696V5.53857H17.7359Z"
      fill="#8D919A"
    />
    <path
      d="M27.7255 23.0314L26.2176 20.7689H27.0006C27.7642 20.7689 28.3852 20.1479 28.3852 19.3843V12.4611C28.3852 11.6974 27.7642 11.0764 27.0006 11.0764H14.5388C13.7752 11.0764 13.1542 11.6974 13.1542 12.4611V19.3843C13.1542 20.1479 13.7752 20.7689 14.5388 20.7689H15.3218L13.814 23.0314C13.3259 23.7639 13.3529 24.7276 13.8811 25.4296L15.2914 27.3107C15.2561 27.4325 15.2311 27.5585 15.2311 27.6921V29.0768C15.2311 29.8404 15.8522 30.4614 16.6158 30.4614V33.923C16.6158 35.0681 17.5476 36 18.6927 36H22.8467C23.9918 36 24.9236 35.0681 24.9236 33.923V30.4614C25.6873 30.4614 26.3083 29.8404 26.3083 29.0768V27.6921C26.3083 27.5585 26.2834 27.4325 26.248 27.3107L27.6576 25.431C28.1865 24.7276 28.2135 23.7632 27.7255 23.0314ZM14.5388 19.3843V12.4611H27.0006V19.3843H25.294L22.4991 15.1916C22.1121 14.6121 21.4662 14.2659 20.7697 14.2659C20.0732 14.2659 19.4273 14.6121 19.0403 15.1916L16.2454 19.3843H14.5388ZM21.462 22.8459C21.462 23.228 21.1519 23.5382 20.7697 23.5382C20.3875 23.5382 20.0774 23.228 20.0774 22.8459C20.0774 22.4637 20.3875 22.1536 20.7697 22.1536C21.1519 22.1536 21.462 22.4637 21.462 22.8459ZM23.539 33.923C23.539 34.3052 23.2288 34.6153 22.8467 34.6153H18.6927C18.3106 34.6153 18.0004 34.3052 18.0004 33.923V30.4614H23.539V33.923ZM16.6158 29.0768V27.6921H24.9236L24.925 29.0768H16.6158ZM26.5513 24.5988L25.24 26.3476C25.1383 26.3234 25.033 26.3075 24.9236 26.3075H16.6158C16.5064 26.3075 16.4012 26.3234 16.2994 26.3476L14.9874 24.5981C14.8116 24.3648 14.8026 24.0436 14.966 23.7999L20.0774 16.1324V20.897C19.2729 21.1836 18.6927 21.9452 18.6927 22.8466C18.6927 23.9917 19.6246 24.9235 20.7697 24.9235C21.9148 24.9235 22.8467 23.9917 22.8467 22.8466C22.8467 21.9452 22.2665 21.1836 21.462 20.897V16.1324L26.5734 23.7992C26.7361 24.0429 26.7271 24.3641 26.5513 24.5988Z"
      fill="#8D919A"
    />
    <path
      d="M4.15381 11.0764H10.3847V12.4611H4.15381V11.0764Z"
      fill="#8D919A"
    />
    <path
      d="M4.15381 13.8466H10.3847V15.2312H4.15381V13.8466Z"
      fill="#8D919A"
    />
    <path
      d="M4.15381 16.6155H10.3847V18.0001H4.15381V16.6155Z"
      fill="#8D919A"
    />
    <path
      d="M4.15381 19.3848H10.3847V20.7694H4.15381V19.3848Z"
      fill="#8D919A"
    />
  </svg>
);

export const DataMarketIcon = () => (
  <svg
    width="36"
    height="36"
    viewBox="0 0 36 36"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.8439 13.1524C13.8439 13.5345 13.5338 13.8446 13.1518 13.8446H4.84539C4.4633 13.8446 4.1532 13.5345 4.1532 13.1524V10.3836C4.1532 10.0015 4.4633 9.69141 4.84539 9.69141H13.1518C13.5338 9.69141 13.8439 10.0015 13.8439 10.3836V13.1524Z"
      fill="#8D919A"
    />
    <path
      d="M29.0723 16.648V2.07659C29.0723 0.931696 28.1406 0 26.9957 0H2.07659C0.931696 0 0 0.931696 0 2.07659V22.8425C0 23.7437 0.580061 24.5044 1.38439 24.791V25.6113C1.38439 26.7562 2.31609 27.6879 3.46098 27.6879H21.6062C22.8695 30.1493 25.4278 31.841 28.3801 31.841C32.5782 31.841 35.9942 28.425 35.9942 24.2269C35.9942 20.262 32.9472 16.999 29.0723 16.648ZM24.0158 17.9971H15.9205C15.5382 17.9971 15.2283 18.307 15.2283 18.6893C15.2283 19.0716 15.5382 19.3815 15.9205 19.3815H22.5109C21.5598 20.5319 20.9431 21.9655 20.8005 23.5347H2.07659C1.6945 23.5347 1.38439 23.2246 1.38439 22.8425V6.92196H27.6879V16.648C26.3298 16.7712 25.0755 17.253 24.0158 17.9971ZM2.07659 1.38439H26.9957C27.3778 1.38439 27.6879 1.6945 27.6879 2.07659V5.53757H1.38439V2.07659C1.38439 1.6945 1.6945 1.38439 2.07659 1.38439ZM3.46098 26.3035C3.07889 26.3035 2.76879 25.9934 2.76879 25.6113V24.9191H20.8012C20.8441 25.3953 20.9341 25.8563 21.0608 26.3035H3.46098ZM28.3801 30.4566C24.9447 30.4566 22.1503 27.6622 22.1503 24.2269C22.1503 20.7915 24.9447 17.9971 28.3801 17.9971C31.8154 17.9971 34.6098 20.7915 34.6098 24.2269C34.6098 27.6622 31.8154 30.4566 28.3801 30.4566Z"
      fill="#8D919A"
    />
    <path
      d="M4.1532 3.46173C4.1532 3.07944 4.46311 2.76953 4.84539 2.76953H10.383C10.7653 2.76953 11.0752 3.07944 11.0752 3.46173C11.0752 3.84402 10.7653 4.15392 10.383 4.15392H4.84539C4.4631 4.15392 4.1532 3.84402 4.1532 3.46173Z"
      fill="#8D919A"
    />
    <path
      d="M15.2283 10.3836C15.2283 10.0013 15.5382 9.69141 15.9205 9.69141H24.2268C24.6091 9.69141 24.919 10.0013 24.919 10.3836C24.919 10.7659 24.6091 11.0758 24.2268 11.0758H15.9205C15.5382 11.0758 15.2283 10.7659 15.2283 10.3836Z"
      fill="#8D919A"
    />
    <path
      d="M4.1532 15.9208C4.1532 15.5385 4.46311 15.2286 4.84539 15.2286H7.61418C7.99647 15.2286 8.30638 15.5385 8.30638 15.9208C8.30638 16.3031 7.99647 16.613 7.61418 16.613H4.8454C4.46311 16.613 4.1532 16.3031 4.1532 15.9208Z"
      fill="#8D919A"
    />
    <path
      d="M4.1532 18.6895C4.1532 18.3072 4.46311 17.9973 4.84539 17.9973H7.61418C7.99647 17.9973 8.30638 18.3072 8.30638 18.6895C8.30638 19.0718 7.99647 19.3817 7.61418 19.3817H4.8454C4.46311 19.3817 4.1532 19.0718 4.1532 18.6895Z"
      fill="#8D919A"
    />
    <path
      d="M9.6908 15.9208C9.6908 15.5385 10.0007 15.2286 10.383 15.2286H13.1518C13.5341 15.2286 13.844 15.5385 13.844 15.9208C13.844 16.3031 13.5341 16.613 13.1518 16.613H10.383C10.0007 16.613 9.6908 16.3031 9.6908 15.9208Z"
      fill="#8D919A"
    />
    <path
      d="M9.6908 18.6895C9.6908 18.3072 10.0007 17.9973 10.383 17.9973H13.1518C13.5341 17.9973 13.844 18.3072 13.844 18.6895C13.844 19.0718 13.5341 19.3817 13.1518 19.3817H10.383C10.0007 19.3817 9.6908 19.0718 9.6908 18.6895Z"
      fill="#8D919A"
    />
    <path
      d="M15.2283 13.1522C15.2283 12.7699 15.5382 12.46 15.9205 12.46H24.2268C24.6091 12.46 24.919 12.7699 24.919 13.1522C24.919 13.5344 24.6091 13.8444 24.2268 13.8444H15.9205C15.5382 13.8444 15.2283 13.5344 15.2283 13.1522Z"
      fill="#8D919A"
    />
    <path
      d="M15.2283 15.9208C15.2283 15.5385 15.5382 15.2286 15.9205 15.2286H24.2268C24.6091 15.2286 24.919 15.5385 24.919 15.9208C24.919 16.3031 24.6091 16.613 24.2268 16.613H15.9205C15.5382 16.613 15.2283 16.3031 15.2283 15.9208Z"
      fill="#8D919A"
    />
    <path
      d="M32.0668 31.1609C31.8455 30.8492 31.9189 30.4171 32.2306 30.1958C32.5424 29.9746 32.9745 30.0479 33.1957 30.3597L35.5994 33.7466C35.8207 34.0583 35.7473 34.4904 35.4356 34.7117C35.1238 34.9329 34.6917 34.8596 34.4705 34.5478L32.0668 31.1609Z"
      fill="#8D919A"
    />
  </svg>
);

export const DevelopmentIcon = () => (
  <svg
    width="34"
    height="34"
    viewBox="0 0 34 34"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.5387 5.53857C4.77506 5.53857 4.15405 4.91756 4.15405 4.15393C4.15405 3.3903 4.77506 2.76929 5.5387 2.76929C6.30233 2.76929 6.92334 3.3903 6.92334 4.15393C6.92334 4.91756 6.30233 5.53857 5.5387 5.53857Z"
      fill="#8D919A"
    />
    <path
      d="M9.69263 5.53857C8.929 5.53857 8.30798 4.91756 8.30798 4.15393C8.30798 3.3903 8.929 2.76929 9.69263 2.76929C10.4563 2.76929 11.0773 3.3903 11.0773 4.15393C11.0773 4.91756 10.4563 5.53857 9.69263 5.53857Z"
      fill="#8D919A"
    />
    <path
      d="M13.8466 5.53857C13.0829 5.53857 12.4619 4.91756 12.4619 4.15393C12.4619 3.3903 13.0829 2.76929 13.8466 2.76929C14.6102 2.76929 15.2312 3.3903 15.2312 4.15393C15.2312 4.91756 14.6102 5.53857 13.8466 5.53857Z"
      fill="#8D919A"
    />
    <path
      d="M29.7698 0H3.46161C1.55288 0 0 1.55288 0 3.46161V29.7698C0 31.6785 1.55288 33.2314 3.46161 33.2314H29.7698C31.6785 33.2314 33.2314 31.6785 33.2314 29.7698V3.46161C33.2314 1.55288 31.6785 0 29.7698 0ZM3.46161 1.38464H29.7698C30.9149 1.38464 31.8468 2.31651 31.8468 3.46161V6.92321H1.38464V3.46161C1.38464 2.31651 2.31651 1.38464 3.46161 1.38464ZM29.7698 31.8468H3.46161C2.31651 31.8468 1.38464 30.9149 1.38464 29.7698V8.30786H31.8468V29.7698C31.8468 30.9149 30.9149 31.8468 29.7698 31.8468Z"
      fill="#8D919A"
    />
    <path
      d="M10.503 14.017L5.56193 18.3405C5.26077 18.6036 5.08838 18.9844 5.08838 19.3845C5.08838 19.7847 5.26077 20.1648 5.56193 20.4286L10.503 24.7514L11.4148 23.7095L6.47371 19.3818L11.4148 15.0589L10.503 14.017Z"
      fill="#8D919A"
    />
    <path
      d="M23.2128 14.0435L22.2456 15.0342L26.7 19.3875L22.2456 23.736L23.2128 24.7267L27.6672 20.3782C27.9365 20.1151 28.0853 19.762 28.0853 19.3854C28.0853 19.0088 27.9365 18.6557 27.6672 18.3926L23.2128 14.0435Z"
      fill="#8D919A"
    />
    <path
      d="M13.1951 28.1497L14.498 28.6191L20.7289 11.3111L19.426 10.8417L13.1951 28.1497Z"
      fill="#8D919A"
    />
  </svg>
);

export const RightArrowIcon = () => (
  <svg
    width="7"
    height="15"
    viewBox="0 0 7 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1 1L6 7.5L1 14"
      stroke="#6F7689"
      stroke-width="1.72135"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

export const MultiUnitIcon = () => (
  <svg
    width="36"
    height="36"
    viewBox="0 0 36 36"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M19.3203 34.9322H5.77627C2.39027 34.9322 0.697266 33.2392 0.697266 29.8532V16.3092C0.697266 12.9232 2.39027 11.2302 5.77627 11.2302H14.2413V29.8532C14.2413 33.2392 15.9343 34.9322 19.3203 34.9322Z"
      stroke="#9CA0AB"
      stroke-width="1.28"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M14.4276 4.45801C14.2921 4.96591 14.2414 5.5246 14.2414 6.15101V11.23H5.77637V7.84401C5.77637 5.98171 7.30007 4.45801 9.16237 4.45801H14.4276Z"
      stroke="#9CA0AB"
      stroke-width="1.28"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M21.0137 11.2302V19.6952"
      stroke="#9CA0AB"
      stroke-width="1.28"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M27.7852 11.2302V19.6952"
      stroke="#9CA0AB"
      stroke-width="1.28"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M26.0927 26.4673H22.7067C21.7755 26.4673 21.0137 27.2291 21.0137 28.1603V34.9323H27.7857V28.1603C27.7857 27.2291 27.0238 26.4673 26.0927 26.4673Z"
      stroke="#9CA0AB"
      stroke-width="1.28"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M7.46875 19.6951V26.4671"
      stroke="#9CA0AB"
      stroke-width="1.28"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M14.2402 29.8533V6.15127C14.2402 2.76527 15.9332 1.07227 19.3192 1.07227H29.4772C32.8632 1.07227 34.5562 2.76527 34.5562 6.15127V29.8533C34.5562 33.2393 32.8632 34.9323 29.4772 34.9323H19.3192C15.9332 34.9323 14.2402 33.2393 14.2402 29.8533Z"
      stroke="#9CA0AB"
      stroke-width="1.28"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

export const NNNInvestmentIcon = () => (
  <svg
    width="45"
    height="35"
    viewBox="0 0 45 35"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M39.3018 29.3196C36.6988 29.3196 34.5912 27.212 34.5912 24.6091V20.5227C34.5912 17.9198 36.6988 15.8122 39.3018 15.8122C41.9047 15.8122 44.0123 17.9198 44.0123 20.5227V24.6091C44.0123 27.212 41.9047 29.3196 39.3018 29.3196Z"
      stroke="#9CA0AB"
      stroke-width="1.2"
    />
    <path
      d="M4.96777 34.7253V7.65514C4.96777 3.39446 7.36202 1.04492 11.7185 1.04492H22.9336C27.272 1.04492 29.6482 3.39446 29.6482 7.65514V33.9333"
      stroke="#9CA0AB"
      stroke-width="1.28"
    />
    <line
      x1="13.3246"
      y1="10.7323"
      x2="21.2904"
      y2="10.7323"
      stroke="#9CA0AB"
      stroke-width="1.28"
      stroke-linecap="round"
    />
    <line
      x1="13.3246"
      y1="15.6491"
      x2="21.2904"
      y2="15.6491"
      stroke="#9CA0AB"
      stroke-width="1.28"
      stroke-linecap="round"
    />
    <line
      x1="17.9476"
      y1="26.2284"
      x2="17.9476"
      y2="33.9025"
      stroke="#9CA0AB"
      stroke-width="1.28"
      stroke-linecap="round"
    />
    <line
      x1="39.6205"
      y1="26.5907"
      x2="39.6205"
      y2="34.2648"
      stroke="#9CA0AB"
      stroke-width="1.28"
      stroke-linecap="round"
    />
    <line
      x1="1.33727"
      y1="34.265"
      x2="34.4473"
      y2="34.265"
      stroke="#9CA0AB"
      stroke-width="1.28"
      stroke-linecap="round"
    />
  </svg>
);

export const RedovelopmentIcon = () => (
  <svg
    width="36"
    height="36"
    viewBox="0 0 36 36"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.549 34.8787H22.7076C31.1731 34.8787 34.5593 31.4925 34.5593 23.0269V12.8683C34.5593 4.40281 31.1731 1.0166 22.7076 1.0166H12.549C4.08347 1.0166 0.697266 4.40281 0.697266 12.8683V23.0269C0.697266 31.4925 4.08347 34.8787 12.549 34.8787Z"
      stroke="#9CA0AB"
      stroke-width="1.27885"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M12.5488 1.0166L20.9297 34.8787"
      stroke="#9CA0AB"
      stroke-width="1.27885"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M16.8326 18.3201L0.697266 23.0269"
      stroke="#9CA0AB"
      stroke-width="1.27885"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M24.7385 12.0642C25.6701 12.0642 26.4253 11.309 26.4253 10.3775C26.4253 9.44587 25.6701 8.69067 24.7385 8.69067C23.807 8.69067 23.0518 9.44587 23.0518 10.3775C23.0518 11.309 23.807 12.0642 24.7385 12.0642Z"
      stroke="#9CA0AB"
      stroke-width="0.810951"
    />
    <path
      d="M20.2084 9.39348C21.2734 4.71159 28.2098 4.717 29.2694 9.39889C29.8911 12.1453 28.1827 14.47 26.6852 15.9081C25.5985 16.9569 23.8793 16.9569 22.7872 15.9081C21.2951 14.47 19.5867 12.1399 20.2084 9.39348Z"
      stroke="#9CA0AB"
      stroke-width="0.810951"
    />
  </svg>
);

export const IndustrialIcon = () => (
  <svg
    width="37"
    height="36"
    viewBox="0 0 37 36"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M18.8652 23.1176V19.7993C18.8652 15.5329 22.319 12.0623 26.6023 12.0623H29.9205"
      stroke="#9CA0AB"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M7.82617 12.0791H11.1445C15.4108 12.0791 18.8815 15.5328 18.8815 19.8161V20.9166V26.8082"
      stroke="#9CA0AB"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M13.5483 34.85H23.7063C32.1713 34.85 35.5573 31.464 35.5573 22.999V12.841C35.5573 4.37599 32.1713 0.98999 23.7063 0.98999H13.5483C5.08327 0.98999 1.69727 4.37599 1.69727 12.841V22.999C1.69727 31.464 5.08327 34.85 13.5483 34.85Z"
      stroke="#9CA0AB"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);
