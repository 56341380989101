import { IonButton } from '@ionic/react';
import axios from 'axios';
import { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { BackBtnIcon, HlyxHeaderLogo } from '../../../assets/icons';
import { ProfileContext } from '../../../context/profileContext';
import './avatarimage.css';
import { baseUrl } from '../../../config';
import { ReactComponent as Profile } from "../../../assets/Profile.svg"
import options from "../../../assets/Options.svg"
import Back from "../../../assets/BackIcon.svg"

const Header = ({ optionsClass }: any) => {
  const { userProfile } = useContext(ProfileContext);
  const [user, setUser] = useState<any>(null);
  const [option, setOption] = useState<boolean>(false);

  // useEffect(() => {
  //   const token = localStorage.getItem('token');
  //   axios
  //     .get(`${baseUrl}/user/find/me`, {
  //       headers: {
  //         Authorization: token,
  //       },
  //     })
  //     .then((res) => {
  //       setUser(res?.data?.user);
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // }, []);
  const cardId = localStorage.getItem('cardId')
  return (
    <div className="sticky-header">
      <div className="profile-header-container">
        <Link to={`/pl/${cardId}`} replace={true}>
          < img src={Back} alt="" />
        </Link>
        <Profile color={'var(--text-color)'} />
        <div style={{ position: "relative" }}>
          <img
            className={optionsClass ? optionsClass : ''}
            src={options}
            alt="options"
            onClick={() => setOption(!option)}
          />
          {option && <div className='option_menu'>
            <p>Option 1</p>
            <div className='divider'></div>
            <p>Option 2</p>
          </div>}
        </div>
      </div>
    </div >
  );
};

export default Header;
