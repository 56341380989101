export const MessagesIcon = () => (
  <svg
    width="18"
    height="17"
    viewBox="0 0 18 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16.982 8.28286C16.9844 9.381 16.7194 10.4632 16.2099 11.436C15.2992 13.1205 13.7736 14.3885 11.951 14.9759C10.1284 15.5633 8.14947 15.4248 6.4265 14.5892L1.52686 16.1691L3.15831 11.436C2.64884 10.4632 2.38384 9.381 2.38621 8.28286C2.39653 6.9587 2.78045 5.66435 3.4938 4.54872C4.20715 3.43309 5.22095 2.5415 6.41857 1.97651C7.43572 1.4813 8.55283 1.22576 9.68412 1.22953H10.1138C11.876 1.29555 13.5518 2.00936 14.8204 3.23424C16.0889 4.45912 16.861 6.10896 16.9886 7.86772L16.982 8.28286Z"
      stroke="white"
      strokeWidth="1.32209"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const SaveIcon = () => (
  <svg
    width="14"
    height="18"
    viewBox="0 0 14 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.7728 16.423L6.76523 12.1315L0.756348 16.423V2.69186C0.756348 2.46615 0.800803 2.24266 0.887177 2.03413C0.97355 1.82561 1.10015 1.63614 1.25975 1.47654C1.41934 1.31695 1.60881 1.19035 1.81734 1.10397C2.02586 1.0176 2.24935 0.973145 2.47506 0.973145H11.058C11.5139 0.973145 11.951 1.15422 12.2734 1.47654C12.5957 1.79886 12.7767 2.23603 12.7767 2.69186L12.7728 16.423Z"
      stroke="white"
      strokeWidth="1.32209"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const ShareFeaturedIcon = () => (
  <svg
    width="16"
    height="17"
    viewBox="0 0 16 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.0475 3.42804C15.0475 3.87466 14.9151 4.31124 14.6669 4.68259C14.4188 5.05394 14.0661 5.34336 13.6535 5.51428C13.2409 5.68519 12.7869 5.72991 12.3488 5.64278C11.9108 5.55565 11.5084 5.34058 11.1926 5.02478C10.8768 4.70897 10.6618 4.30661 10.5746 3.86858C10.4875 3.43055 10.5322 2.97652 10.7031 2.5639C10.8741 2.15128 11.1635 1.79861 11.5348 1.55048C11.9062 1.30236 12.3428 1.16992 12.7894 1.16992C13.3883 1.16992 13.9626 1.40783 14.3861 1.83131C14.8096 2.25479 15.0475 2.82915 15.0475 3.42804V3.42804Z"
      stroke="white"
      strokeWidth="1.32209"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.01496 8.6966C6.01496 9.14321 5.88253 9.5798 5.6344 9.95114C5.38628 10.3225 5.0336 10.6119 4.62099 10.7828C4.20837 10.9537 3.75434 10.9985 3.3163 10.9113C2.87827 10.8242 2.47591 10.6091 2.16011 10.2933C1.8443 9.97753 1.62924 9.57517 1.54211 9.13714C1.45498 8.6991 1.4997 8.24507 1.67061 7.83245C1.84152 7.41983 2.13095 7.06716 2.5023 6.81904C2.87364 6.57091 3.31023 6.43848 3.75684 6.43848C4.05338 6.43848 4.34702 6.49688 4.62099 6.61037C4.89496 6.72385 5.14389 6.89018 5.35357 7.09986C5.56326 7.30955 5.72959 7.55848 5.84307 7.83245C5.95656 8.10642 6.01496 8.40006 6.01496 8.6966V8.6966Z"
      stroke="white"
      strokeWidth="1.32209"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M15.0475 13.9664C15.0475 14.413 14.9151 14.8496 14.6669 15.2209C14.4188 15.5923 14.0661 15.8817 13.6535 16.0526C13.2409 16.2235 12.7869 16.2682 12.3488 16.1811C11.9108 16.094 11.5084 15.8789 11.1926 15.5631C10.8768 15.2473 10.6618 14.8449 10.5746 14.4069C10.4875 13.9689 10.5322 13.5148 10.7031 13.1022C10.8741 12.6896 11.1635 12.3369 11.5348 12.0888C11.9062 11.8407 12.3428 11.7083 12.7894 11.7083C13.3883 11.7083 13.9626 11.9462 14.3861 12.3696C14.8096 12.7931 15.0475 13.3675 15.0475 13.9664V13.9664Z"
      stroke="white"
      strokeWidth="1.32209"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5.70709 9.8335L10.8487 12.8293"
      stroke="white"
      strokeWidth="1.32209"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.8407 4.56494L5.70709 7.56079"
      stroke="white"
      strokeWidth="1.32209"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
