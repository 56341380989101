import {
  MediumIcon,
  NumFourIcon,
  NumOneIcon,
  NumThreeIcon,
  NumTwoIcon,
  SoundCloudIcon,
} from "../../assets/icons";
import { DriveIcon } from "../../assets/icons/index";
import {
  BiDashboardIcon,
  DataMarketIcon,
  DevelopmentIcon,
  IndustrialIcon,
  MultiUnitIcon,
  NNNInvestmentIcon,
  RedovelopmentIcon,
  UiUxIcon,
} from "../../assets/icons/ServiceTabIcons";
import {
  BehanceIcon,
  Facebook,
  InstagramIcon,
  LinkedinIcon,
  MaskIcon,
  MediumSocialIcon,
  ShipFaceIcon,
  SkypeIcon,
  SlackIcon,
  Twitter,
  YoutubeIcon,
} from "../../assets/icons/SocialIcons";
import SliderOneImage from "../../assets/media/slide-one-image.png";
import SliderThreeImage from "../../assets/media/slide-three-image.png";
import SliderTwoImage from "../../assets/media/slide-two-image.png";

export const JamesprofessionData = [
  {
    lineColor: "blue",
    title: "Company",
    description: "Oriyn LLC",
  },
  {
    lineColor: "green",
    title: "Title / Role",
    description: "Chief Executive Officer",
  },
  {
    lineColor: "purple",
    title: "Occupation / Profession",
    description: "Entrepreneur / Founder",
  },
  {
    lineColor: "orange",
    title: "Industry",
    description: "Information Technology",
  },
];

export const educationData = [
  {
    description: `College at <span style="font-weight:600">Stanford University</span>`,
  },
  { description: "Masters Degree in Business Marketing" },
  { description: "Graduated Class of 2000" },
];

export const accolatesData = [
  {
    description: "Board Chair at",
    institute: " Boston Medical",
    date: "November 1,2022",
  },
  {
    description: "Deans List 4 Years in a row at",
    institute: " Stanford University",
    date: "August 2017 - May 2022",
  },
  {
    description: "Summa Cum Laude, Honors Club from",
    date: "August 2017 - May 2022 ",
    institute: " Stanford University",
  },
];

export const acheivementData = [
  {
    year: "2023",
    description: (
      <>
        People Magazine{" "}
        <span style={{ fontWeight: 600, color: "var(--text-color)" }}>
          {" "}
          Scientist of The Year
        </span>
      </>
    ),
  },
  {
    year: "2022",
    description: (
      <>
        Harvard Medical Journal{" "}
        <span style={{ fontWeight: 600, color: "var(--text-color)" }}>
          Prestigious Honor Recipient
        </span>{" "}
      </>
    ),
  },
  {
    year: "2021",
    description: (
      <>
        <span style={{ fontWeight: 600, color: "var(--text-color)" }}>
          Presidential Honorin
        </span>{" "}
        Genetic Science
      </>
    ),
  },
];

export const goalsData = [
  {
    icon: <NumOneIcon />,
    description: "Raise $200M in funding for R&D",
  },
  {
    icon: <NumTwoIcon />,
    description:
      "Discover a working nano-molecular bio-therapy to fight cellular necrosy",
  },
  {
    icon: <NumThreeIcon />,
    description:
      "Attract top scientific talent in the fields of Biology and Nano-technology",
  },
  {
    icon: <NumFourIcon />,
    description: `Be listed on <span style="font-weight:600">Forbes Magazines<br /> ‘40 under 40’</span> in the next 2 years`,
  },
];

export const needsData = [
  {
    date: "January 28, 2022",
    heading: "New Company Website",
    status: "Active",
    description:
      "Looking for someone that can create a new company website for us",
  },
  {
    date: "December 11, 2022",
    heading: "Find Business Partner",
    status: "Active",
    description:
      "Looking for business partner that has experience building bio-tech companies",
  },
  {
    date: "November 8, 2022",
    heading: "Seeking Experienced Scientist",
    status: "Filled",
    description:
      "Looking for someone that can create a new company website for us",
  },
];

export const loremText =
  "Biology is my first love. As a Scientist my principal mission in life is the pursuit of life-changing cures. I founded Sphere Bio with the goal of discovering science that Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation.";

export const JamesText =
  "I build digital products and ventures that help entrepreneurs and brands succeed. Through data-driven insights and custom software, my team and I specialize in crafting solutions that help companies solve real challenges and achieve game-changing results.";

export const achieveData = [
  {
    achieveGoal: "Create DNA Nano Map",
    description: "Create DNA nano-map sequencing",
    date: "Nov. 1, 2022",
  },
  {
    achieveGoal: "Compete AMT Certification",
    description: "Get AMT Certification by end of year",
    date: "Oct. 8, 2022",
  },
  {
    achieveGoal: "Acquire New Business Office",
    description: "Close on new office location for 2022",
    date: "Sep. 1, 2022",
  },
];

export const brakerData = [
  {
    description: "Hobbies",
    tags: ["Martial Arts", "Stargazing", "Art"],
  },
  {
    description: "Interest",
    tags: ["Reading", "Legos", "Music"],
  },
  {
    description: "Skills",
    tags: ["Painting", "Piano", "Gardening"],
  },
];

export const sliderData = [
  {
    img: SliderOneImage,
    imgAlt: "cel DNA Mapping",
    title: "Cell DNA Mapping",
    subtitle: "Nano-tech AI",
  },
  {
    img: SliderTwoImage,
    imgAlt: "cell-interpolization",
    title: "Cell Interpolization",
    subtitle: "Discovery Process",
  },
  {
    img: SliderThreeImage,
    imgAlt: "DNA Therapy",
    title: "DNA Therapy",
    subtitle: "Cell Rejuvenation",
  },
  {
    img: SliderOneImage,
    imgAlt: "cel DNA Mapping",
    title: "Cell DNA Mapping",
    subtitle: "Nano-tech AI",
  },
  {
    img: SliderTwoImage,
    imgAlt: "cell-interpolization",
    title: "Cell Interpolization",
    subtitle: "Discovery Process",
  },
  {
    img: SliderThreeImage,
    imgAlt: "DNA Therapy",
    title: "DNA Therapy",
    subtitle: "Cell Rejuvenation",
  },
];

export const galleryData = [
  {
    img: SliderOneImage,
    imgAlt: "cell-interpolization",
    title: "Cell Interpolization",
    publishDate: "Dec. 21, 2022",
  },
  {
    img: SliderTwoImage,
    imgAlt: "cell-interpolization",
    title: "Cell Interpolization",
    publishDate: "Dec. 21, 2022",
  },
  {
    img: SliderThreeImage,
    imgAlt: "cell-interpolization",
    title: "Cell Interpolization",
    publishDate: "Dec. 21, 2022",
  },
  {
    img: SliderOneImage,
    imgAlt: "cell-interpolization",
    title: "Cell Interpolization",
    publishDate: "Dec. 21, 2022",
  },
  {
    img: SliderTwoImage,
    imgAlt: "cell-interpolization",
    title: "Cell Interpolization",
    publishDate: "Dec. 21, 2022",
  },
  {
    img: SliderThreeImage,
    imgAlt: "cell-interpolization",
    title: "Cell Interpolization",
    publishDate: "Dec. 21, 2022",
  },
];

export const SocialData = [
  {
    link: "https://www.youtube.com/",
    icon: <YoutubeIcon color={"var(--social-back)"} />,
    label: "YouTube",
  },
  {
    link: "https://www.youtube.com/",
    icon: <SlackIcon color={"var(--social-back)"} />,
    label: "Slack",
  },
  {
    link: "https://www.youtube.com/",
    icon: <ShipFaceIcon color={"var(--social-back)"} />,
    label: "Reddit",
  },
  {
    link: "https://www.youtube.com/",
    icon: <MaskIcon color={"var(--social-back)"} />,
    label: "Discord",
  },
  {
    link: "https://www.youtube.com/",
    icon: <Twitter color={"var(--social-back)"} />,
    label: "Twitter",
  },
  {
    link: "https://www.youtube.com/",
    icon: <Facebook color={"var(--social-back)"} />,
    label: "Facebook",
  },
  {
    link: "https://www.youtube.com/",
    icon: <BehanceIcon color={"var(--social-back)"} />,
    label: "Behance",
  },
  {
    link: "https://www.youtube.com/",
    icon: <SkypeIcon color={"var(--social-back)"} />,
    label: "Skype",
  },
  {
    link: "https://www.youtube.com/",
    icon: <InstagramIcon color={"var(--social-back)"} />,
    label: "Instagram",
  },
  {
    link: "https://www.youtube.com/",
    icon: <MediumSocialIcon color={"var(--social-back)"} />,
    label: "Medium",
  },
];

export const SocialMediaIcons: any = {
  LinkedIn: <LinkedinIcon color={"var(--social-back)"} />,
  YouTube: <YoutubeIcon color={"var(--social-back)"} />,
  Slack: <SlackIcon color={"var(--social-back)"} />,
  Reddit: <ShipFaceIcon color={"var(--social-back)"} />,

  Discord: <MaskIcon color={"var(--social-back)"} />,

  Twitter: <Twitter color={"var(--social-back)"} />,

  Facebook: <Facebook color={"var(--social-back)"} />,

  Behance: <BehanceIcon color={"var(--social-back)"} />,

  Skype: <SkypeIcon color={"var(--social-back)"} />,

  Instagram: <InstagramIcon color={"var(--social-back)"} />,

  Medium: <MediumSocialIcon color={"var(--social-back)"} />,
};

export const ServicesIcons: any = {
  BiDashboard: <BiDashboardIcon />,
  UiUx: <UiUxIcon />,
  Devolpment: <DevelopmentIcon />,

  DataMarketing: <DataMarketIcon />,

  MultiUnit: <MultiUnitIcon />,

  Investment: <NNNInvestmentIcon />,

  Redevolpment: <RedovelopmentIcon />,

  Industrial: <IndustrialIcon />,
};

export const BlogLinkIcons: any = {
  Medium: <MediumIcon color={"var(--social-back)"} />,
  GoogleDrive: <DriveIcon color={"var(--social-back)"} />,
  SoundCloud: <SoundCloudIcon color={"var(--social-back)"} />,
};

export const LOGIN_SECRET = "hlyx2358";